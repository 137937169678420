.experience-component.experience-assets-categoryTiles {
    h2,
    h3,
    p,
    link {
        color: $black;
    }

    picture {
        display: block;
    }

    img {
        display: block;
        width: 100%;
        aspect-ratio: 1 / 1;
    }

    h2 {
        font-family: $eurostile;
        font-size: 36px;
        font-weight: 500;
        line-height: 43.2px;
        margin-bottom: 20px;
        text-align: center;
    }

    h3 {
        font-size: 18px;
        line-height: 18px;
        font-weight: 700;
        text-transform: none;
        margin: 0 0 5px 0;
    }

    p {
        margin: 0 0 5px 0;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
    }

    .pd-category-tiles-container {
        max-width: 1980px;
        margin: auto;
        padding: 0 22px;
        width: calc(100% - 44px);
        @include respond(largeUp) {
            width: 90%;
        }
    }

    .pd-cta-buttons {
        margin-right: 0;

        a {
            font-size: 12px;
            line-height: 20px;
            font-weight: 400;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .pd-category-tiles {
        display: flex;
        flex-wrap: wrap;
        gap: 14px;
        justify-content: center;
        position: relative;

        @include respond(largeUp) {
            gap: 20px;
        }

        .pd-category-tile {
            flex: calc(50% - 14px);

            @include respond(largeUp) {
                width: 100%;
                flex: 1;
            }
        }
    }

    .pd-category-tiles-description {
        padding: 16px 0;
        text-align: center;

        @include respond(largeUp) {
            padding: 30px 10px 10px;
        }
    }

    .placeholder_desktop,
    .placeholder_mobile {
        display: none;
    }
}
