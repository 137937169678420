.experience-component.experience-assets-bannerInsider {
    h2 {
        margin-bottom: 40px;
        font-family: $eurostileext;
        font-weight: 500;
    }

    h3 {
        margin-bottom: 30px;
    }

    p {
        margin-bottom: 30px;
    }

    .pd-banner-description {
        background: $alto-pd;
        position: relative;

        .pd-banner-description-inner {
            padding: 50px 20px 30px;

            @include respond(largeUp) {
                position: absolute;
                padding: 80px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 80%;
            }
        }

		.pd-cta-buttons {

			margin-bottom: 15px;

			&:nth-of-type(1) {
				margin-right: 5px;
				margin-left: -5px;
				position: relative;
			}

			&:nth-of-type(2) {
				margin-right: 5px;
			}

			.link {
                font-size: 15px;
                font-weight: 400;
                display: block;
                border: none;
                height: 38px;
                line-height: 38px;
                padding: 0 20px;
                background: $black;
                color: $white;

				&:hover {
					text-decoration: none;
					background: $black;
					color: $white;
                    border-radius: 0;
				}
			}
		}
    }

    .pd-banner-image-wrapper {
        display: none;

        @include respond(largeUp) {
            display: block;
        }
    }
}
