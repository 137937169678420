.experience-component.experience-assets-bannerSpotlight {
  picture {
    display: block;
  }

	img {
    	display: block;
    	width: 100%;
		aspect-ratio: 3/4;
		object-fit: cover;
  	}

	h2 {
		font-family: $eurostile;
    	font-size: 24px;
    	text-align: center;
	  	margin-bottom: 30px;

		@include respond(largeUp) {
			font-size: 32px;
		}
  	}

  h3 {
      margin-bottom: 12px;
	  font-size: 18px;
	  font-weight: 700;
  }

	p {
      	margin-bottom: 12px;
	  	font-size: 12px;
		line-height: 20px;
  	}

	.pd-cta-buttons {
		&:nth-of-type(1) {
			margin: 0;
		}
		a {
			font-weight: 400;
			display: block;
			font-size: 12px;
	  }
  }


	.pd-collection-tiles-container {
		@include respond(largeUp) {
			display: flex;
			gap: 20px;
			justify-content: center;
		 	width: calc(100% + 20px);
		 	position: relative;
		}

        .pd-collection-tile {
          width: auto;

         @include respond(largeUp) {
              width: calc(34.33% - 20px);
          }
      }

	  .slick-list {
		padding: 0 50px;

		.pd-collection-tile {
			margin: 0 10px;
		}
	  }

	.slick-arrow {
		position: absolute;
		width: 50px;
		height: 70px;
		bottom: 0;
		top: unset;
		z-index: 10;

		&.slick-prev {
			left: 0;
			border: none;
		}

		&.slick-next {
			right: 0;
			border: none;
		}
	}
  }

  .pd-banner-collection-description {
    padding: 30px 10px 10px;
    text-align: center;
  	@include respond(small) {
		opacity: 0;
		transition: opacity 1.1s ease-in-out;

	}
  }

	.slick-active {
		.pd-banner-collection-description {
			@include respond(small) {
				opacity: 1;
			}
		}
	}

  .placeholder_desktop,
  .placeholder_mobile {
    display: none;
  }
}
