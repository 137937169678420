.experience-component.experience-assets-reviews_banner {
    .pd-reviews-banner {
        display: flex;

        .slick-track {
            display: flex !important;
        }

        .slick-arrow {
            border: 0;

            &.slick-prev {
                left: 10px;
            }

            &.slick-next {
                right: 10px;
            }
        }

        .pd-review-container {
            background-color: $alto-pd;
            flex: 1;

            &:nth-of-type(2) {
                background-color: $gray-lighter;
            }
        }

        .pd-reviews-banner-review {
            padding: 40px;

            .pd-review {
                font-size: 14px;
                line-height: 20px;
            }

            .pd-reviewer {
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
                margin: 0;
                text-transform: uppercase;
            }
        }
    }
}
