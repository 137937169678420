/////View port breakpoints
$iphone5: 320px;
$iphone6: 375px;
$extra-small-breakpoint: 480px;
$small-breakpoint: 880px;
$standard-breakpoint: 1200px;
$large-breakpoint: 1385px;

// Map Club Glove brand Colors
$lightestgrey: #EFEFEF;
$lightgrey: #ADADAD;
$darkgrey: #6A6A6A;
$extradarkgrey: #1C1C1C;
$primary-orange: $black;
$secondary-orange: #6A6A6A;
$greyfont: #999;
$alto-pd: #D9D9D9;
$dustygray: #9A9A9A;

//remap footjoy colors
$gray-pale: $lightestgrey;
$gray-mediumer: $lightgrey;
$gray-darker: $darkgrey;
$blue-light: $lightgrey;
$blue-medium: $darkgrey;
$blue-dark: $darkgrey;
$dark-navy: $extradarkgrey;


// colors
$black: #000;
$white: #fff;
$white-bg: #fff;
$gray-dark: $darkgrey;
$gray-warm: $lightgrey;
$gray-light: $lightestgrey;
$gray-white: $lightestgrey;
$gray-medium: $lightgrey;
$gray-light-bk: #F3F3F1;
$red: #FF0000;
$green: #4B984B;
$gray-cirlce: #f2f2f2;
$trans: transparent;
$clear: rgba(0, 0, 0, 0);
$white-smoke: #f2efef;


//Pneumatic color names
$error-red: #FF0000;
$error-background: #FFF3F3;
$clp-border: $lightgrey;
$primary-color: $black;
$body-bg-color: $white;

$globalLetterSpacing: normal !important;
$border-radius: 4px;
$navfontsize: 18px;
$defaultfontsize: 16px;
$smallfontsize: 9px;


//these populate the color selection swatches (might not need it when we get to PDP)
$swatch-types:
  "black" #000000,
  "beige" #f5f5dc,
  "blue" blue,
  "light_blue" #8fc2e7,
  "tan" #9e8063,
  "purple" #67339a,
  "red" #cc1a21,
  "whiteroyalblue" #4169e1,
  "whitesilver" #c0c0c0,
  "whitelightgreycharcoal" #444444,
  "brown" #42280e,
  "lightgreynavyberry" #5f192c,
  "whitegreyblue" #778899,
  "navyorangelime" orange,
  "white" white,
  "green" #266b2e,
  "grey" #93969a,
  "pink" #fc77ac,
  "orange" #f47b25,
  "white" #ffffff,
  "yellow" #fff200,
  "blackwhitelightgrey" #ccc,
  "lightgreygreennavy" lightgreen,
  "navy" #28304c,
  "blackred" darkred,
  "blacklimelightgrey" lime,
  "lime" #74c44e'';

//animation/transition speeds
$quickSpeed: .5s;

//text-transform value for links
$linkCase: uppercase;
