html {
	&.freeze-scroll {
        overflow: hidden !important;
    }
}

body {
	background: $body-bg-color; //$white;
    color: $darkgrey;
    @include font-regular(12px);
	font-family: $neuzeit;
	font-size: 17px;
	letter-spacing: normal;
}

p {
	font-family: $neuzeit;
	font-size: 17px;    
}

h1,h2 {
	font-family: $neuzeit;
    font-size: 22px;
    color: $black;
	letter-spacing: normal !important;
}

h1 {
	font-size: rem(22px);
	font-weight: 400;
    text-transform: none !important;
    font-family: $eurostileext;
	margin: .625rem 0;
	@include respond(largeUp) {
		margin: 0 0 14px;
	}
}

h2 {
	font-size: rem(22px);
	text-transform: none;
	font-weight: 400;
    font-family: $neuzeit;
}

h3 {
    font-family: $neuzeit;
    font-size: rem(22px);
	color: $darkgrey;
	font-weight: 400;
}

a {
	color: $black;
	overflow: hidden;
	text-decoration: none;
	letter-spacing: normal !important;

	&:not(.UsableNetAssistive) {
		outline: none;
	}
    &:hover {
        text-decoration: underline;
    }
}

fieldset {
	border: 0 none;
	margin: 0;
	padding: 0;
}

table {
	width: 100%;
}

th {
	text-align: left;
}

td {
	padding: 1em;
	vertical-align: top;
}

#main{
	margin-bottom: 1em;
}

//the global page-width wrapper
.innerWidth{
	margin: 0 auto;
	max-width: $standard-breakpoint;
	clear: both;

	@include respond(large) {
		max-width: $small-breakpoint;
	}
	@include respond(small) {
		max-width: $small-breakpoint;
	}

	&:after{
		content: '';
		clear: both;
		float: none;
		display: block;
	}
}
form{
	form-row.error{
		.error,
		.error-message{
			color: $error-red;
		}
	}
}
#wrapper{
	.error-form{
		border: 0;
		float: left;
		width: 100%;
	}
}

.refinement-header-wrapper {
	padding-top: 14px;

	.refinement-header {
		font-weight: normal;
	}
}
.section-header {
	font-weight: 400;
}

.ui-tooltip-content,
.ui-dialog #dialog-container p {
	font-family: $suisseintregular;
}

.overlay-layer {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	background-color: $white;
	&.show {
		display: block;
	}
}
