.pd-features-global {
	padding: 0 14px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	gap: 10px;

	@include respond(largeUp) {
		flex-direction: row;
		padding: 0;
	}

	.pd-features-global-left {
		flex-basis: 100%;
		position: relative;
		display: flex;
		flex-direction: column-reverse;

		@include respond(largeUp) {
			display: block;
			flex-basis: 40%;
			width: 40%;
		}

		.pd-features-image {
			object-fit: cover;
			width: 100%;
            aspect-ratio: 1 / 1;
		}
	}

	.pd-features-global-left-content-inner {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		box-sizing: border-box;
		padding: 0 40px 40px 40px;
		display: none;
		@include respond(largeUp) {
			display: block;
		}
	}

	.pd-features-global-left-content,
	.pd-features-mobile-buttons {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		@include respond(largeUp) {
			justify-content: flex-start;
		}

		.button {
            text-transform: uppercase;
			text-decoration: none;
			margin: 0 5px 10px 5px;
			@include respond(largeUp) {
				margin: 0 10px 10px 0;
			}
		}
	}

	.pd-features-global-right {
		flex-basis: 100%;
		display: flex;
		flex-direction: column;

		@include respond(largeUp) {
			flex-basis: 60%;
			width: 60%;
		}

		.pd-features-global-right-content {
			text-align: center;

			@include respond(largeUp) {
				padding-left: 20px;
				text-align: left;
			}
		}
		.pd-features-heading {
			font-family: $eurostile;
			font-size: 18px;

			@include respond(largeUp) {
				font-size: 48px;
				font-weight: 500;
				margin: 20px auto 40px;
			}
		}

		.pd-features-sub-heading {
			display: none;

			@include respond(largeUp) {
				display: block;
			}
		}
	}

	.pd-features-static-img-desktop {
		display: none;
		@include respond(largeUp) {
			object-fit: contain;
			display: block;
		}
	}

	.pd-features-static-img-mobile {
		object-fit: contain;
        margin: 30px auto 0;
		display: block;
        @include respond(largeUp) {
			display: none;
		}
	}

	.pd-features-mobile-buttons {
		display: flex;
		padding-bottom: 20px;

		@include respond(small) {
			margin-right: 0 !important;
		}

		@include respond(largeUp) {
			display: none;
		}
	}

	.slick-slider {
		margin-bottom: 0;

		.slick-arrow {
			position: absolute;
			width: 50px;
			height: 70px;
			bottom: 0;
			top: unset;
			opacity: 1;
			z-index: 10;

			@include respond(largeUp) {
				bottom: unset;
				top: 50%;
			}

			&.slick-prev {
				left: 0;
				border: none;

				@include respond(largeUp) {
					left: -15px;
				}
			}

			&.slick-next {
				right: 0;
				border: none;

				@include respond(largeUp) {
					right: -35px
				}
			}
		}
	}

	.pd-features-global-left-image {
		height: 100%;
	}
}
