.experience-featuresSlider {
	display: flex;
	.experience-assets-producttile {
		padding: 0 10px;
		box-sizing: border-box;
		@include respond(largeUp) {
			flex-basis: 33.33%;
			padding: 0 0 0 20px;
		}
	}
}

.pd-features-global-carousel {
	margin-top: auto;
	width: calc(100% + 28px);
	margin-left: -14px;
	@include respond(largeUp) {
		width: 100%;
		margin-left: 0;
	}
}

.pd-product-tile-image {
	aspect-ratio: 1/1;
	background: $gray-lighter;
	margin-bottom: 20px;
	img {
		aspect-ratio: 1/1;
		object-fit: cover;
        width: 100%;
	}
}

.pd-product-tile-name {
	a {
		color: $black;
		font-size: 14px;
		font-weight: 600;
		line-height: 18px;
		text-align: center;

		@include respond(largeUp) {
			font-size: 14px;
			text-align: left;
		}
	}
}

.pd-product-tile-content {
	text-align: center;
	@include respond(largeUp) {
		text-align: left;
	}
}

.pd-product-tile-content-bottom {
	display: flex;
	flex-direction: column;
	@include respond(largeUp) {
		justify-content: space-between;
		flex-direction: row;
	}
}

.pd-product-tile-category,
.pd-product-tile-price {
	color: $black;
	font-size: 14px;
	font-weight: 400;
	text-transform: none;
}

.pd-product-tile-category {
	margin-bottom: 10px;
	@include respond(largeUp) {
		margin-bottom: 0;
	}
}

.pd-product-rating {
	.p-w-r {
		.pr-category-snippet {
			margin: 0 auto;
			@include respond(largeUp) {
				margin: 0;
			}

			.pr-category-snippet__item.pr-category-snippet__total {
				display: none !important;
			}
		}
	}
}

.pd-product-tile-content {
	@include respond(small) {
		padding: 0 10px;
		opacity: 0;
		transition: opacity 1.1s ease-in-out;
	}
}

.slick-current {
	.pd-product-tile-content {
		@include respond(small) {
			opacity: 1;
		}
	}
}
