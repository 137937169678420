.experience-component.experience-assets-2up_banner {
    .overlay-banner {
        position: relative;
    }

    h2,
    h3,
    .link {
        color: $black;

        &.text-grey {
            color: $gray-white;

            @include respond(small) {
                color: $black;
            }
        }
    }

    picture {
        display: block;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        object-fit: cover;
        aspect-ratio: 16 / 9;
    }

    .has-image,
    .cls-image-wrapper,
    .overlay-banner {
        height: 100%;
    }

    .pd-2up-banner {
        display: flex;
        flex-direction: column;

        @include respond(largeUp) {
            flex-direction: row;
            justify-content: space-between;
        }

        .element-1,
        .element-2 {
            width: 100%;
            padding-bottom: 20px;

            @include respond(largeUp) {
                width: calc(50% - 10px);
            }
        }
    }

    .pd-banner-2up-description {
        position: absolute;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        left: 0;
        width: 100%;
        padding: 0 30px 10px 0;
        z-index: 2;
        justify-content: flex-end;
        height: 100%;
        bottom: 0;

        @include respond(largeUp) {
            padding: 20px;
        }

        @include respond(small) {
            position: relative;
            background-color: $alto-pd;
            padding: 14% 20px 12%;
        }

        @include respond(standard) {
            padding: 50px;
        }

        &.bg-gradient {
            background: linear-gradient(45deg, #ffffffde, transparent);
            @include respond(small) {
                background: linear-gradient(45deg, #ffffffde, transparent);
            }
        }

        &.bg-gradient-dark {
            background: linear-gradient(45deg, #000000de, transparent);
            @include respond(small) {
                background: linear-gradient(45deg, #000000de, transparent);
            }
        }
    }

    .pd-cta-buttons {
        margin: 0;
    }

    h3 {
        display: block;
        margin: 0 0 10px 20px;
    }

    h2 {
        font-family: $eurostileext;
        font-size: 32px;
        line-height: 32px;
        font-weight: 500;
        margin: 0 0 0 20px;
        text-transform: uppercase;
        padding-bottom: 16px;
    }

    .link {
        display: block;
        border: none;
        height: 38px;
        line-height: 38px;
        padding: 0 20px;

        &:hover {
            @include respond(largeUp) {
                color: $white;
                background-color: $black;
                border-radius: 0;
                text-decoration: none;
            }
        }
    }
}
