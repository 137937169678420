///////////////////////
/// Button Mixins
///////////////////////

@mixin blackButton {
    color: $white;
	background-color: $darkgrey;
    border-color: $darkgrey; //override to brand orange for kjus
    border: solid 2px $darkgrey;
    &:hover{
        color: $white;
        background-color: $black;
        border: solid 2px $black;
    }
}

@mixin whiteButton {
    color: $white;
    border-color: $white;
    &:hover{
        color: $white;
        background-color: $darkgrey;
		border-color: $darkgrey;
    }
}

///////////////////////
/// Base Button Styles
///////////////////////
button,
.button,
.buttonstyle,
input[type="button"] {
	background-color: $darkgrey;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 48px;
	border-radius: $border-radius;
	cursor: pointer;
	display: inline-block;
	font-size: 16px;
	box-sizing: border-box;
	font-family: $suisseintregular;
    text-transform: uppercase;
	text-align: center;
	color: $white;
	transition: background-color $quickSpeed;
	font-weight: normal;
	letter-spacing: $globalLetterSpacing;
	outline: none;
	@include blackButton;
	&.black {
		@include blackButton;
	}
	&.white {
		@include whiteButton;
	}
	&.orange {
		background-color: $primary-orange;
	}
	&.inverted {
		background-color: $white;
		color: $black;
        border: solid 2px $black;
		&:hover{
			background-color: $black;
			color: $white;
		}
	}

	&.large {
		font-size: 1.35em;
		text-transform: uppercase;
	}
	&[disabled],
	&.disabled {
		background-color: $gray-light;
		border-color: $gray-light;
		color: $gray-dark;
		opacity: 0.5;
	}

	//Content asset helper functions (moved from _content_asset_slots.scss)
	&.mobile-black {
		@include blackButton;
	}
	&.mobile-white {
		@include whiteButton;
	}
	@include respond(largeUp){
		&.desktop-black {
			@include blackButton;
		}
		&.desktop-white {
			@include whiteButton;
		}
	}
	@include respond(large){
		&.tablet-black {
			@include blackButton;
		}
		&.tablet-white {
			@include whiteButton;
		}
	}
}

.button {
    &:hover{
        color: $white;
        background-color: $black;
    }
}

.button-fancy-large {
	@extend .button.large;
	font-size: 14px;
}

.button-text {
	background-color: transparent;
	border-width: 0;
	color: $red;
	padding: 0;
	&:hover {
		background-color: transparent;
		text-decoration: underline;
	}
}

button#needle_categoryNT.needlepoint {
	border:0!important;
}

.button-full-width {
	padding-left: 0;
	padding-right: 0;
	text-align: center;
	width: 100%;
}
///////////////////////
/// Tab Buttons
///////////////////////
.tab-button-large {
	display: none;
	// these are not enabled at this time
	@include borderbox;
	padding: 20px 40px 20px 40px;
	color: $black;
	background-color: $gray-white;

	@include font-size(18px);
	&.selected{
		background-color: $gray-light;
	}
}
.tab-button-large {
	@include borderbox;
	padding: 15px 60px 15px 60px;
	color: $black;
	background-color: $white;

	@include font-size(12px);
	&.selected{
		background-color: $white;
		color: $white;
	}
}

.textbutton {
	background: none;
}
