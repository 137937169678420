.pd-explore-banner {
    padding: 20px 0;
    margin: 0 auto;
    @include respond(largeUp) {
        max-width: 90%;
        display: flex;
        justify-content: space-between;
        padding: 50px 0;
    }

    .pd-image-container {
        @include respond(largeUp) {
            width: 36%;
        }

        img {
            border-radius: 6px;
        }
    }

    .pd-text-container {
        text-align: left;
        @include respond(largeUp) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 64%;
            padding-left: 50px;

            box-sizing: border-box;
        }

        h4 {
            margin: 10px 0;
            font-size: 10px;
            color: $black;

            @include respond(largeUp) {
                font-size: 22px;
                font-weight: 700;
                margin: 0 0 10px 0;
            }
        }
        p {
            margin: 0;
            font-size: 10px;
            color: $black;
            @include respond(largeUp) {
                font-size: 16px;
            }
        }
    }
}


.pd-explore-banner-border {
    margin: 0 auto;
    border-bottom: 1px solid $dustygray;
    width: 100%;
    @include respond(largeUp) {
        width: 80%;
    }
}
