@import '../../../../module_pagedesigner/cartridge/scss/default/mixins/text_crop.scss';
@import '../../../../app_clubglove/cartridge/scss/default/footjoy/_variables.scss';
@import '../../../../app_clubglove/cartridge/scss/default/clubglove/_variables.scss';
@import '../../../../app_clubglove/cartridge/scss/default/_mixins.scss';
@import '../../../../app_clubglove/cartridge/scss/default/clubglove/_fonts.scss';
@import '../../../../app_clubglove/cartridge/scss/default/clubglove/_base_elements.scss';
@import '../../../../app_clubglove/cartridge/scss/default/clubglove/_buttons.scss';

//PD components styling
$white: #fff;

.experience-component {

    @include respond(largeUp) {
        width: 100%;
        margin: 0 auto;
        max-width: 2200px;
        box-sizing: border-box;
    }

    &.experience-layouts-tabbedLayout,
    &.experience-assets-bannerSpotlight,
    &.experience-layouts-featuresLayout {
        @include respond(largeUp) {
            width: 90%;
            max-width: 1980px;
        }
    }

    h2,
    h3,
    .link {
        color: $black;

        &.text-grey {
            color: $gray-white;
        }
    }

    a {
        font-family: $neuzeit;
        font-weight: 400;

        &.button {
            text-decoration: none !important;
        }
    }

    h2 {
        font-size: 36px;
        line-height: 34px;
        margin: 0 0 20px;
        font-family: $eurostileext;
        font-weight: 500;
        text-transform: uppercase;
        color: $black;

        br {
            display: none;

            @include respond(largeUp) {
                display: block;
            }
        }

        @include respond(standard) {
            font-size: 42px;
            line-height: 40px;
            margin-bottom: 16px;
        }
    }

    h3 {
        line-height: 18px;
        font-size: 18px;
        margin: 0 0 16px;
        text-transform: uppercase;
        font-family: $neuzeit;
        font-weight: 400;
        letter-spacing: 0;
        color: $black;

        br {
            display: none;

            @include respond(largeUp) {
                display: block;
            }
        }

        @include respond(standard) {
            margin-bottom: 16px;
        }
    }

    p {
        @include text-crop($line-height: 1.38);
        font-size: 12px;
        margin-bottom: 20px;
        font-family: $eurostile;
        text-transform: none;
        color: $black;

        br {
            display: none;

            @include respond(largeUp) {
                display: block;
            }
        }
    }

    .pd-cta-buttons {
        display: inline-block;
    }

    .link {
        font-family: $neuzeit;
        font-size: 15px;
        text-transform: uppercase;
        text-decoration: underline;
        color: $black;

        &.white {
            color: $white;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .button {
        background: $black;
        border-radius: 0;
        border: none;
        color: $white;
        font-family: $neuzeit;
        font-size: 15px;
        font-weight: 400;
        height: 38px;
        line-height: 38px;
        padding: 0 20px;

        &:hover {
            color: $black;
            background: $gray-white;
        }

        &.grey {
            background: $gray-white;
            color: $black;

            &:hover {
                color: $gray-white;
                background: $black;
            }
        }
    }

    .slick-arrow {
        background: none;
        opacity: 1;
        top: unset;
        bottom: 0;

        @include respond(largeUp) {
            top: 50%;
            transform: translateY(-50%);
        }

        &::before {
            content: "";
            position: absolute;
            display: block;
            width: 12px;
            height: 12px;
            top: 50%;
            left: 50%;
            border: solid #000;
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
        }

        &:focus,
        &:hover,
        &:active {
            border: none;
        }

        &.slick-prev {
            left: 10%;

            @include respond(extraSmallUp) {
                left: 20%;
            }

            @include respond(largeUp) {
                left: -17px;
            }

            &::before {
                border-width: 0 0 3px 3px;
            }
        }

        &.slick-next {
            right: 10%;

            @include respond(extraSmallUp) {
                right: 20%;
            }

            @include respond(largeUp) {
                right: -37px;
            }

            &::before {
                border-width: 3px 3px 0 0;
            }
        }

        &.slick-disabled {
            display: none !important;
        }
    }
}

.placeholder_desktop,
.spacer_desktop {
    display: none;

    @include respond(largeUp) {
        display: block;
    }
}

.placeholder_mobile,
.spacer_mobile {
    @include respond(largeUp) {
        display: none;
    }
}

.placeholder_desktop,
.placeholder_mobile {
    overflow: hidden;
    background: #ebebeb;
    width: 100%;
    position: relative;
}

.video-pagedesigner-container {
    position: relative;
    padding: 56% 0 0 0;

    &.video-pagedesigner-container-mobile {
        padding: 45% 0 0 0;
        aspect-ratio: 3 / 4;
    }
}

.cls-image-wrapper {
    position: relative;
    display: block;

    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 2;
    }
}

.mobile-hide-pd {
    display: none !important;

    @include respond(largeUp) {
        display: block!important;
    }
}

.mobile-show-pd {
    display: block !important;

    @include respond(largeUp) {
        display: none !important;
    }
}


/**
* Page Designer Components:
**/
@import "components/hero_banner";
@import "components/category_tiles";
@import "components/2up_banner";
@import "components/tabs";
@import "components/features";
@import "components/pd_product_tile";
@import "components/reviews_banner";
@import "components/banner_insider";
@import "components/banner_spotlight";
@import "components/explore_banner";
