:root {
	--text-crop-border-color: transparent;
}

@mixin text-crop($line-height: 1.2, $top-adjustment: 0px, $bottom-adjustment: 0px) {
	// Configured in Step 1
	$top-crop: 9;
	$bottom-crop: 8;
	$crop-font-size: 36;
	$crop-line-height: 1.2;

	// Apply values to calculate em-based margins that work with any font size
	$dynamic-top-crop: max(($top-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0) / $crop-font-size;
	$dynamic-bottom-crop: max(($bottom-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0) / $crop-font-size;

	// Mixin output
	line-height: $line-height;

	&::before,
	&::after {
		content: '';
		display: block;
		height: 0;
		width: 100%;
		border-bottom: 0.01px solid var(--text-crop-border-color);
	}

	&::before {
		margin-bottom: calc(-#{$dynamic-top-crop}em + #{$top-adjustment});
	}

	&::after {
		margin-top: calc(-#{$dynamic-bottom-crop}em + #{$bottom-adjustment});
	}
}
// Mixin generated at: http://text-crop.eightshapes.com/?typeface-selection=custom-font&typeface=Lato&custom-typeface-name=bolton&custom-typeface-url=https%3A%2F%2Fwww.fila.com%2Fon%2Fdemandware.static%2FSites-FILA-Site%2F-%2Fen_US%2Fv1712843409089%2Fcss%2Ffila-global.min.css&custom-typeface-weight=00&custom-typeface-style=normal&weight-and-style=100&size=36&line-height=1.2&top-crop=9&bottom-crop=8