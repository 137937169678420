.tabbed-layout {
	padding: 0;
	text-align: center;

	.tabs-wrapper {
		display: none;
	}

	.nav-tabs {
		@include respond(extra-small) {
			margin: 0 20px;
		}
		padding: 0;
		margin: auto;
		display: flex;
		list-style: none;
		justify-content: center;

		li {
			height: 0;
			width: 0;

			&.tab-show {
				height: auto;
				width: auto;
				margin-bottom: 30px;
				padding-bottom: 10px;
				border-bottom: 1px solid $black;

				@include respond(largeUp) {
					margin-bottom: 30px;
				}

				.nav-link-rec,
				.nav-link {
					font-size: 15px;
					font-weight: 400;
					line-height: 42px;
					height: 44px;
					padding: 0 24px;
					margin: 0;
					color: $black;
					display: block;
					text-transform: uppercase;
					text-decoration: none;
					white-space: nowrap;

					&.active {
						background: $black;
						color: $white;
					}
					@include respond(largeUp) {
						&:hover {
							background: $black;
							color: $white;
						}
					}
				}

				&:first-child {
					.nav-link {
						margin-left: 0;
					}
				}

				&:last-child {
					.nav-link {
						margin-right: 0;
					}
				}
			}
		}
	}
}

.tab-content-global {
	margin: 0 auto;
	@include respond(largeUp) {
		width: calc(100% + 40px);
		position: relative;
		left: -20px;
	}

	.tab-pane {
		display: none;

		&.active {
			display: block;

			.experience-region {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				padding: 0 7px;
				@include respond(largeUp) {
					padding: 0 10px;
				}
				> div {
					width: 50%;
					padding: 0 7px 14px 7px;
					box-sizing: border-box;
					@include respond(largeUp) {
						width: 25%;
						padding: 0 10px;
					}
				}
			}
		}
	}
}

.tabbed-layout-heading {
	text-align: center;

	h3 {
		font-size: 36px;
        font-family: "eurostile", sans-serif;
        line-height: 43.2px;
        font-weight: 500;
	}
}

.experience-component {
	p {
		&.pd-category-sub-header {
			text-transform: capitalize;
			color: $black;
			margin: 0;
			padding: 30px 0 10px 0;
			font-weight: 700;
			font-size: 18px;
			@include respond(largeUp) {
				padding: 30px 0 10px 0;
			}
		}

		&.pd-category-body-text {
			font-weight: 400;
			font-size: 14px;
			margin: 0;
			padding: 0 0 5px 0;
			margin-bottom: 5px;
			color: $black;
		}
	}

	.pd-category-link {
		color: $black;
		font-size: 14px;
		padding: 5px;
		text-decoration: underline;
		font-weight: 700;
		&:hover {
			text-decoration: none;
			background: $black;
			color: $white;

		}
	}

	.pd-category-image {
		aspect-ratio: 1 / 1;
		object-fit: cover;
	}
}
