/////View port breakpoints
$iphone5: 320px;
$iphone6: 375px;
$extra-small-breakpoint: 480px;
$tablet-regular: 768px;
$small-breakpoint: 880px;
$standard-breakpoint: 1200px;
$break-xLarge: 1330px;
$large-breakpoint: 1385px;
$break-xl: 1440px;
$break-max: 1850px;
$max-nav-width: $break-xl;
$max-menu-width: $break-xl;
$suggestion-breakpoint: 940px;

// colors
$black: #000;
$white: #fff;
$white-bg: #fff;
$gray-clean: #222;
$gray-dark: #333;
$gray-darker: #d3d3d3;
$gray-warm: #999;
$gray-warm2: #8a8a8a;
$gray-light: #e6e6e6;
$gray-light2: #eeeeee;
$gray-light3: #cccccc;
$gray-lighter: #ececec;
$gray-border: #bebebe;
$gray-white: #f7f7f7;
$gray-medium: #767676;
$gray-medium2: #666666;
$gray-strong: #4a4a4a;
$gray-outline: #b5b5b5;
$gray-light-bk: #ececec;
$gray-dark-bk: #e1e1e1;
$grey-inactive: #f0f0f0;
$grey-label: #7e7e7e;
$grey-crumbs: #919191;
$gray-line: #dedede;

$red: #994c4c;
$red-sale: #d32300;
$green: #4B984B;
$green-dark: #528f7a;
$gray-cirlce: #f2f2f2;
$trans: transparent;
$clear: rgba(0, 0, 0, 0);
$white-smoke: #f2efef;
$lightgreen: #7ED321;

// Redesign Colors
$gray-pale: #F8F8F8;
$gray-mediumer: #9B9B9B;
$blue-light: #68A1B8;
$blue-light2: #6ba4b8;
$green-light: #518f79;
$blue-medium: #4D738A;
$blue-medium2: #4d90ab;
$blue-dark: #25495F;
$dark-navy: #252837;
$almost-black: #1a1a1a;
$dark: #1C1E29;

//Pneumatic color names
$field-border-color: #cccccc;
$field-border-narrow: 1px solid $field-border-color;
$field-border-bold: 2px solid $field-border-color;
$error-red: #b2595c;
$clp-border: #e7e7e7;
$primary-color: $black;
$body-bg-color: $white;

$globalLetterSpacing: 0.05em;

//these populate the color selection swatches (might not need it when we get to PDP)
$swatch-types:
  "black" #000000,
  "beige" #f5f5dc,
  "blue" blue,
  "light_blue" #8fc2e7,
  "tan" #9e8063,
  "purple" #67339a,
  "red" #cc1a21,
  "whiteroyalblue" #4169e1,
  "whitesilver" #c0c0c0,
  "whitelightgreycharcoal" #444444,
  "brown" #42280e,
  "lightgreynavyberry" #5f192c,
  "whitegreyblue" #778899,
  "navyorangelime" orange,
  "white" white,
  "green" #266b2e,
  "grey" #93969a,
  "pink" #fc77ac,
  "orange" #f47b25,
  "white" #ffffff,
  "yellow" #fff200,
  "blackwhitelightgrey" #ccc,
  "lightgreygreennavy" lightgreen,
  "navy" #28304c,
  "blackred" darkred,
  "blacklimelightgrey" lime,
  "lime" #74c44e'';

//animation/transition speeds
$quickSpeed: .5s;

//text-transform value for links
$linkCase: uppercase;


// -------------------
// Standard Dimensions
// -------------------

$nav-height-desktop: 86px;
$nav-height-mobile: 90px;

// -----
// Fonts
// -----

$font-default: "din-condensed", sans-serif;
$font-small: "neuzeit-grotesk", sans-serif;


