.experience-component {
    .pd-banner-image-wrapper {
        position: relative;

        @include respond(largeUp) {
            flex-basis: 60%;
        }

        &.mobile-padding {
            padding-left: 15px;
            padding-right: 15px;

            @include respond(largeUp) {
                padding: 0;
            }
        }

        .img {
            margin-bottom: -3px;
        }
    }

    .image-container {
        height: 100%;
    }

    picture {
        display: block;
        height: 100%;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .has-bottom-spacing .cls-image-wrapper {
        margin-bottom: 20px;

        @include respond(largeUp) {
            margin-bottom: 30px;
        }
    }

    .block-banner {
        display: flex;
        flex-direction: column-reverse;

        @include respond(largeUp) {
            display: flex;
            flex-direction: row;
        }
    }

    .overlay-banner {
        position: relative;
    }

    .container-banner {
        margin: 0 auto;
    }

    .pd-banner-description {
        background-color: $alto-pd;
        position: relative;

        @include respond(largeUp) {
            flex-basis: 40%;
        }

        .pd-banner-description-inner {
            text-align: center;
            padding: 14% 20px 12%;

            @include respond(largeUp) {
                position: relative;
                top: 50%;
                left: 50%;
                padding: 10%;
                transform: translate(-50%, -50%);
                text-align: left;
            }
        }
    }

    .pd-hero-banner-overlay-description {
        text-align: center;
        position: absolute;
        z-index: 3;
        bottom: 50px;
        margin: 0;
        text-align: center;
        padding: 0 8%;
        width: calc(100% - 16%);

        @include respond(largeUp) {
            padding: 0 10%;
            position: absolute;
            bottom: unset;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 3;
            max-width: 100%;
            box-sizing: border-box;
        }

        &.align-left {
            text-align: left;
            @include respond(largeUp) {
                left: 0;
                right: auto;
                transform: translate(0, -50%);
            }
        }
        &.align-right {
            text-align: right;
            @include respond(largeUp) {
                left: auto;
                right: 0;
                transform: translate(0, -50%);
            }
        }
    }
}
