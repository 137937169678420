// fonts
$titillium: 'Titillium Web', sans-serif; //regular & bold
$oswald: 'Oswald', sans-serif; //light and bold
$arial: Arial, sans-serif; //regular
$din-condensed: din-condensed, sans-serif;
$din2014narrow: din-2014-narrow, sans-serif;
$bebas: bebas-neue-pro, serif;
$bebas-semi: bebas-neue-pro-semiexpanded, serif;
$neuzeit: neuzeit-grotesk, sans-serif;
$titillium: $neuzeit;
$oswald: $neuzeit;
$titillium: $neuzeit;
$suisseintlight: $neuzeit;
$suisseintmedium: $neuzeit;
$suisseintregular: $neuzeit;
$eurostile: 'eurostile', sans-serif;
$eurostileext: 'eurostile-extended', sans-serif;
$brushscript: 'brush-script-std', sans-serif;
$avantgarde: 'itc-avant-garde-gothic-pro', sans-serif;


// usage: @include font-regular();
// ex: @include font-regular(14px);  //sets a font size in rems with a fallback in pxs
// ex2: @include font-regular(14px, $medium-breakpoint);  //sets a scaling font size in vw's with a fixed fallback in pxs

@mixin font-small($size:"", $breakpoint:"") {
    font-family: $neuzeit;
    font-weight: 400;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}
@mixin font-light($size:"", $breakpoint:"") {
    font-family: $neuzeit;
    font-weight: 400;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}
@mixin font-regular($size:"", $breakpoint:"") {
    font-family: $neuzeit;
    font-weight: 400;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}
@mixin font-semibold($size:"", $breakpoint:"") {
    font-family: $neuzeit;
    font-weight: 600;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}
@mixin font-bold($size:"", $breakpoint:"") {
    font-family: $neuzeit;
    font-weight: 700;
    @if $size != "" {
        @include font-size($size, $breakpoint: "")
    }
}
